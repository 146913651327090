import * as TYPES from "./types";
import * as API from "./api";
import { requestStatus } from "../../../services";

export default {
  [TYPES.GET_DOMAINS](context, filter) {
    API.getDomains(filter).then(({ response, status }) => {
      if (status === requestStatus.success) {
        context.commit(TYPES.SET_DOMAINS, response.data);
      }
    });
  },
  [TYPES.GET_DOMAINS_FOR_SETTINGS](context, filter) {
    API.getDomains(filter).then(({ response, status }) => {
      if (status === requestStatus.success) {
        context.commit(TYPES.SET_DOMAINS_FOR_SETTINGS, response.data);
      }
    });
  },
  [TYPES.EDIT_DOMAIN]({ commit }, { id, data }) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "edit",
      request_id: id,
    });
    API.editDomains(id, data).then(({ response, status }) => {
      if (status === requestStatus.success) {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.success,
          field: "edit",
        });
      } else {
        commit(TYPES.CHANGE_STATUS, {
          ...response,
          field: "edit",
          messages: response.messages || "Edit data error.",
        });
      }
    });
  },
  [TYPES.BULK_EDIT_DOMAINS]({ commit }, data) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "bulk_edit",
    });
    API.bulkEitDomains(data).then(({ response, status }) => {
      if (status === requestStatus.success) {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.success,
          field: "bulk_edit",
        });
      } else {
        commit(TYPES.CHANGE_STATUS, {
          ...response,
          field: "bulk_edit",
          messages: response.messages || "Edit data error.",
        });
      }
    });
  },
  [TYPES.GET_DOMAIN](context, id) {
    API.getDomain(id).then(({ response, status }) => {
      if (status === requestStatus.success) {
        context.commit(TYPES.SET_DOMAIN, response.data);
      }
    });
  },
  [TYPES.GET_DOMAIN_STATS]({ commit }, filter) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "load_stats",
    });
    API.getDomainStats(filter)
      .then(({ response, status }) => {
        if (status === requestStatus.success) {
          commit(TYPES.SET_DOMAIN_STATS, response.data);
          commit(TYPES.CHANGE_STATUS, {
            status: requestStatus.success,
            field: "load_stats",
          });
        } else {
          commit(TYPES.CHANGE_STATUS, {
            status: requestStatus.error,
            field: "load_stats",
          });
        }
      })
      .catch(() => {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.error,
          field: "load_stats",
        });
      });
  },

  [TYPES.GET_DOMAIN_URLS]({ commit }, id) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "load_urls",
    });
    API.getUrls(id)
      .then(({ response, status }) => {
        if (status === requestStatus.success) {
          commit(TYPES.SET_DOMAIN_URLS, response.data);
          commit(TYPES.CHANGE_STATUS, {
            status: requestStatus.success,
            field: "load_urls",
          });
        } else {
          commit(TYPES.CHANGE_STATUS, {
            status: requestStatus.error,
            field: "load_urls",
          });
        }
      })
      .catch(() => {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.error,
          field: "load_urls",
        });
      });
  },

  [TYPES.GET_TAGS]({ commit }, data) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "load_tags",
    });
    API.getTags(data).then((response) => {
      if (response.status === requestStatus.success) {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.success,
          field: "load_tags",
        });
        commit(TYPES.RECEIVE_TAGS, response.response.data);
      } else {
        commit(TYPES.CHANGE_STATUS, {
          ...response,
          field: "load_tags",
          messages: response.messages || "Load tags error",
        });
      }
    });
  },
};
