import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/domains",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/domains",
        name: "domains",
        component: () => import("@/view/pages/Domains/Domains.vue"),
      },
    ],
  },
  {
    path: "/rules",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/rules",
        name: "rules",
        component: () => import("@/view/pages/Rules/Rules.vue"),
      },
    ],
  },
  {
    path: "/domains/:id",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/domains/:id",
        name: "domain",
        component: () => import("@/view/pages/Domain/Domain.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-1.vue"),
  },
  {
    path: "/login",
    component: () => import("@/view/pages/auth/Auth"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/view/pages/auth/Login"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
