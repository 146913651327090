import actions from "./actions";
import mutations from "./mutations";

const state = {
  filter: {
    limit: 50,
    offset: 0,
  },
  default_filter: true,
  page_name: "",
};
export default {
  state,
  actions,
  mutations,
};
