import Http from "@/core/services/api.service";
import { service_url } from "../../../config";

export function login(data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth/login/`,
    method: "post",
    data: { ...data },
  });
}

export function logout() {
  return Http._axios({
    url: `${service_url}/api/v1/auth/logout/`,
    method: "post",
  });
}

export function setToken(provider, data) {
  return Http._axios({
    url: `${service_url}/api/v1/auth/token/${provider}/`,
    method: "post",
    data: data,
  });
}
