import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
export * from "./types";

export default {
  state: {
    classes: {},
  },
  getters,
  actions,
  mutations,
};
