import * as TYPES from "./types";
export default {
  /**
   * Set and replace the whole config
   * @param state
   * @param payload
   */
  [TYPES.SET_LAYOUT_CONFIG](state, payload) {
    state.commit(TYPES.SET_LAYOUT_CONFIG, payload);
  },

  /**
   * Reset the config at the initial state
   * @param state
   */
  [TYPES.RESET_LAYOUT_CONFIG](state) {
    state.commit(TYPES.RESET_LAYOUT_CONFIG);
  },

  /**
   * Reset the config using saved config in the cache by the layout builder
   * @param state
   */
  [TYPES.OVERRIDE_LAYOUT_CONFIG](state) {
    state.commit(TYPES.OVERRIDE_LAYOUT_CONFIG);
  },

  /**
   * Override config by page level
   * @param state
   * @param payload
   */
  [TYPES.OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
    state.commit(TYPES.OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
  },
};
