import * as TYPES from "./types";

export default {
  [TYPES.APPEND_BREADCRUM](state, payload) {
    state.breadcrumbs = [...state.breadcrumbs, payload];
  },
  [TYPES.SET_BREADCRUMB](state, payload) {
    state.breadcrumbs = payload;
  },
};
