import Http from "@/core/services/api.service";
import { service_url } from "../../../config";

export function getRules(filter) {
  return Http._axios({
    url: `${service_url}/api/v1/rules/?${filter ? filter : ""}`,
    method: `get`,
  });
}

export function createRule(data) {
  return Http._axios({
    url: `${service_url}/api/v1/rules/`,
    method: `post`,
    data: data,
  });
}
export function editRules(id, data) {
  return Http._axios({
    url: `${service_url}/api/v1/rules/${id}/`,
    method: `patch`,
    data: data,
  });
}

export function bulkEitRules(data) {
  return Http._axios({
    url: `${service_url}/api/v1/rules/edit/bulk/`,
    method: `patch`,
    data: data,
  });
}
