import objectPath from "object-path";

export default {
  /**
   * Get config from layout config
   * @param state
   * @returns {function(path, defaultValue): *}
   */
  layoutConfig: (state) => (path, defaultValue) => {
    return objectPath.get(state.config, path, defaultValue);
  },
};
