// action types
export const GET_RULES = "GET_RULES";
export const GET_RULES_FOR_SETTINGS = "GET_RULES_FOR_SETTINGS";
export const EDIT_RULE = "EDIT_RULE";
export const GET_RULE = "GET_RULE";
export const GET_RULE_STATS = "GET_RULE_STATS";
export const GET_NEW_RULES = "GET_NEW_RULES";
export const BULK_EDIT_RULES = "BULK_EDIT_RULES";
export const GET_RULE_URLS = "GET_RULE_URLS";
export const GET_TAGS = "GET_TAGS";

// mutation types
export const SET_RULES = "SET_RULES";
export const SET_RULES_FOR_SETTINGS = "SET_RULES_FOR_SETTINGS";
export const SET_NEW_RULES = "SET_NEW_RULES";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const SET_RULE = "SET_RULE";
export const SET_RULE_STATS = "SET_RULE_STATS";
export const SET_RULE_URLS = "SET_RULE_URLS";
export const RECEIVE_TAGS = "RECEIVE_TAGS";
