// action types
export const GET_DOMAINS = "GET_DOMAINS";
export const GET_DOMAINS_FOR_SETTINGS = "GET_DOMAINS_FOR_SETTINGS";
export const EDIT_DOMAIN = "EDIT_DOMAIN";
export const GET_DOMAIN = "GET_DOMAIN";
export const GET_DOMAIN_STATS = "GET_DOMAIN_STATS";
export const GET_NEW_DOMAINS = "GET_NEW_DOMAINS";
export const BULK_EDIT_DOMAINS = "BULK_EDIT_DOMAINS";
export const GET_DOMAIN_URLS = "GET_DOMAIN_URLS";
export const GET_TAGS = "GET_TAGS";

// mutation types
export const SET_DOMAINS = "SET_DOMAINS";
export const SET_DOMAINS_FOR_SETTINGS = "SET_DOMAINS_FOR_SETTINGS";
export const SET_NEW_DOMAINS = "SET_NEW_DOMAINS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_DOMAIN_STATS = "SET_DOMAIN_STATS";
export const SET_DOMAIN_URLS = "SET_DOMAIN_URLS";
export const RECEIVE_TAGS = "RECEIVE_TAGS";
