import moment from "moment";

export const DATE_FORMAT_TIME = "DD.MM.YYYY HH:mm";

export const requestStatus = {
  loading: "loading",
  success: "success",
  error: "error",
  error_connection: "error_connection",
  logout: "logout",
};

export const renderDate = (date) => {
  return moment(date).format(DATE_FORMAT_TIME);
};

export const calculatePagesCount = (data, elements) => {
  return Math.ceil(+data / +elements);
};

export const isEmptyObject = (obj) => {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
};

export function buildFilterString(query) {
  let result = "";
  for (let key in query) {
    result += query[key] !== undefined ? `&${key}=${query[key]}` : "";
  }
  return result.substring(1, result.length);
}

export function debounce(func, wait, immediate) {
  let timeout;
  return () => {
    let context = this,
      args = arguments;
    let later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function createDateInUTC() {
  const date = new Date();
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export const DATE_FORMAT = "DD.MM.YYYY";
// export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = "DD.MM.YYYY HH:mm";

export const DATETIME_API_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_API_FORMAT = "YYYY-MM-DD";

export const getAllCurrentMonth = () => {
  let dt = createDateInUTC();
  dt.setDate(1);
  let dt_to = createDateInUTC();
  dt_to.set;
  return [dt, new Date(dt_to.getFullYear(), dt_to.getMonth() + 1, 0)];
};

export const getCurrentMonth = () => {
  let dt = createDateInUTC();
  dt.setDate(1);
  return [dt, createDateInUTC()];
};

export const getStartOfWeek = () => {
  const currentDate = new Date(); // Отримуємо поточну дату і час
  const currentDayOfWeek = currentDate.getDay(); // Отримуємо день тижня (0 - неділя, 1 - понеділок, і так далі)
  const daysToSubtract = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1; // Визначаємо, скільки днів потрібно відняти

  currentDate.setDate(currentDate.getDate() - daysToSubtract); // Віднімаємо дні, щоб отримати початок тижня

  return currentDate;
};

export const getPreviousCurrentMonth = () => {
  const today = new Date();

  const firstDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  return { date_from: firstDayOfMonth, date_to: lastDayOfMonth };
};
