import * as TYPES from "./types";
import * as API from "./api";
import { requestStatus } from "../../../services";

export default {
  [TYPES.GET_RULES](context, filter) {
    API.getRules(filter).then(({ response, status }) => {
      if (status === requestStatus.success) {
        context.commit(TYPES.SET_RULES, response.data);
      }
    });
  },
  [TYPES.GET_RULES_FOR_SETTINGS](context, filter) {
    API.getRules(filter).then(({ response, status }) => {
      if (status === requestStatus.success) {
        context.commit(TYPES.SET_RULES_FOR_SETTINGS, response.data);
      }
    });
  },
  [TYPES.EDIT_RULE]({ commit }, { id, data }) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "edit",
      request_id: id,
    });
    API.editRules(id, data).then(({ response, status }) => {
      if (status === requestStatus.success) {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.success,
          field: "edit",
        });
      } else {
        commit(TYPES.CHANGE_STATUS, {
          ...response,
          field: "edit",
          messages: response.messages || "Edit data error.",
        });
      }
    });
  },
  [TYPES.BULK_EDIT_RULES]({ commit }, data) {
    commit(TYPES.CHANGE_STATUS, {
      status: requestStatus.loading,
      field: "bulk_edit",
    });
    API.bulkEitRules(data).then(({ response, status }) => {
      if (status === requestStatus.success) {
        commit(TYPES.CHANGE_STATUS, {
          status: requestStatus.success,
          field: "bulk_edit",
        });
      } else {
        commit(TYPES.CHANGE_STATUS, {
          ...response,
          field: "bulk_edit",
          messages: response.messages || "Edit data error.",
        });
      }
    });
  },
};
