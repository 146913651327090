import * as TYPES from "./types";

export default {
  [TYPES.SET_ERROR](state, error) {
    state.errors = error;
  },
  [TYPES.SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    localStorage.setItem("user", JSON.stringify(payload));
  },
  [TYPES.PURGE_AUTH](state) {
    state.isAuthenticated = false;
    localStorage.removeItem("user");
  },
};
