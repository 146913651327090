import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JSON.parse(localStorage.getItem("user")),
};

export default {
  state,
  actions,
  mutations,
  getters,
};
