import * as TYPES from "./types";
import merge from "deepmerge";

export default {
  [TYPES.SET_LAYOUT_CONFIG](state, payload) {
    state.config = payload;
  },
  [TYPES.RESET_LAYOUT_CONFIG](state) {
    state.config = Object.assign({}, state.initial);
  },
  [TYPES.OVERRIDE_LAYOUT_CONFIG](state) {
    state.config = state.initial = Object.assign(
      {},
      state.initial,
      JSON.parse(localStorage.getItem("config"))
    );
  },
  [TYPES.OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
    state.config = merge(state.config, payload);
  },
};
