import Vue from "vue";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store/store";
import { VERIFY_AUTH } from "./core/services/store/modules/auth/types";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/modules/config/config";
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";

Vue.config.productionTip = false;
Vue.use(Toaster, { timeout: 5000 });

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
// import vuetify from "./core/plugins/vuetify";
// import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
// import "./core/plugins/perfect-scrollbar";
// import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(`config/${RESET_LAYOUT_CONFIG}`);

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(`auth/${VERIFY_AUTH}`)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) =>
      createElement("span", { class: { "la-angle-down": true, la: true } }),
  },
  Deselect: {
    render: (createElement) =>
      createElement("span", { class: { "la-times": true, la: true } }),
  },
});

new Vue({
  router,
  store,
  // vuetify,
  render: (h) => h(App),
}).$mount("#app");
