import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
export * from "./types";

export default {
  state: {
    stats: [],
  },
  getters,
  actions,
  mutations,
};
