import Http from "@/core/services/api.service";
import { service_url } from "../../../config";

export function getDomains(filter) {
  return Http._axios({
    url: `${service_url}/api/v1/domains/?${filter ? filter : ""}`,
    method: `get`,
  });
}

export function editDomains(id, data) {
  return Http._axios({
    url: `${service_url}/api/v1/domains/${id}/`,
    method: `patch`,
    data: data,
  });
}

export function bulkEitDomains(data) {
  return Http._axios({
    url: `${service_url}/api/v1/domains/edit/bulk/`,
    method: `patch`,
    data: data,
  });
}

export function getDomain(id) {
  return Http._axios({
    url: `${service_url}/api/v1/domains/${id}/`,
    method: `get`,
  });
}

export function getDomainStats(filter) {
  return Http._axios({
    url: `${service_url}/api/v1/domains/stat?${filter ? filter : ""}`,
    method: `get`,
  });
}

export function getUrls(id) {
  return Http._axios({
    url: `${service_url}/api/v1/urls/?limit=10&ordering=-last_visit_at&domain=${id}`,
    method: `get`,
  });
}

export function getTags(filter) {
  return Http._axios({
    url: `${service_url}/api/v1/tags/?${filter ? filter : ""}`,
    method: "get",
  });
}
