import KTUtil from "@/assets/js/components/util.js";
window.KTUtil = KTUtil;

import KTHeader from "@/assets/js/components/header.js";
window.KTHeader = KTHeader;

import KTMenu from "@/assets/js/components/menu.js";
window.KTMenu = KTMenu;

import KTScrolltop from "@/assets/js/components/scrolltop.js";
window.KTScrolltop = KTScrolltop;

import KTToggle from "@/assets/js/components/toggle.js";
window.KTToggle = KTToggle;

// Metronic layout base js
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
window.KTLayoutAside = KTLayoutAside;

import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
window.KTLayoutAsideMenu = KTLayoutAsideMenu;

import KTLayoutHeader from "@/assets/js/layout/base/header.js";
window.KTLayoutHeader = KTLayoutHeader;

import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
window.KTLayoutHeaderMenu = KTLayoutHeaderMenu;

import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
window.KTLayoutHeaderTopbar = KTLayoutHeaderTopbar;

import KTLayoutStickyCard from "@/assets/js/layout/base/sticky-card.js";
window.KTLayoutStickyCard = KTLayoutStickyCard;

import KTLayoutSubheader from "@/assets/js/layout/base/subheader.js";
window.KTLayoutSubheader = KTLayoutSubheader;

import KTLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";
window.KTLayoutScrolltop = KTLayoutScrolltop;

import KTOffcanvas from "@/assets/js/components/offcanvas.js";
window.KTOffcanvas = KTOffcanvas;
