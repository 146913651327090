import * as TYPES from "./types";

export default {
  [TYPES.SET_BREADCRUMB](state, payload) {
    state.commit(TYPES.SET_BREADCRUMB, payload);
  },
  [TYPES.ADD_BREADCRUMB](state, payload) {
    if (typeof payload === "object") {
      payload.forEach((item) => state.commit(TYPES.APPEND_BREADCRUM, item));
    } else {
      state.commit(TYPES.APPEND_BREADCRUM, payload);
    }
  },
};
