import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
export * from "./types";

export default {
  state: {
    list: [],
    count: 0,
    new_domains_list: [],
    new_domains_count: 0,
    domains_list_for_settings: [],
    status: { change_field: null },
    stats: [],
    details: {},
    urls: [],
    tags: [],
  },
  getters,
  actions,
  mutations,
};
