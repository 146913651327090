import * as TYPES from "./types";

export default {
  [TYPES.CHANGE_STATUS](state, payload) {
    state.status = {
      ...state.status,
      [payload.field]: payload.status,
      messages: payload.messages,
      change_field: payload.field,
    };
  },

  [TYPES.SET_RULES](state, payload) {
    state.count = payload.count;
    state.list = [...payload.results];
  },

  [TYPES.SET_RULES_FOR_SETTINGS](state, payload) {
    state.RULES_list_for_settings = [...payload.results];
  },

  [TYPES.SET_NEW_RULES](state, payload) {
    state.new_RULES_count = payload.count;
    state.new_RULES = [...payload.results];
  },

  [TYPES.SET_RULE](state, payload) {
    state.details = { ...payload };
  },
};
