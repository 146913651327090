import * as TYPES from "./types";
import * as API from "./api";
import { requestStatus } from "../../../services";

export default {
  [TYPES.LOGIN](context, credentials) {
    API.login(credentials)
      .then(({ response, status, messages }) => {
        if (status === requestStatus.success) {
          context.commit(TYPES.SET_AUTH, response.data);
        } else {
          context.commit(TYPES.SET_ERROR, [messages]);
        }
      })
      .catch(({ messages }) => {
        context.commit(TYPES.SET_ERROR, [messages]);
      });
  },

  [TYPES.LOGOUT](context) {
    context.commit(TYPES.PURGE_AUTH);
    if (context.state.isAuthenticated) {
      API.logout().then(() => {});
    }
  },

  [TYPES.VERIFY_AUTH](context) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.token) {
      context.commit(TYPES.SET_AUTH, user);
    } else {
      context.commit(TYPES.PURGE_AUTH);
    }
  },
};
