import * as types from "./types";
import moment from "moment";

const pageInitFilter = {
  limit: 50,
  offset: 0,
};

export default {
  [types.SET_PAGE](state, payload) {
    state.default_filter = true;
    payload.page_name && (state.page_name = payload.page_name);
    let filter = { ...pageInitFilter, ...(payload.filter || {}) };

    if (!filter.ordering && state.page_name != "rules") {
      filter.ordering = "-last_visit_at";
    }
    if (state.page_name != "rules") {
      if (!filter.is_whitelisted && filter.is_whitelisted !== "") {
        filter.is_whitelisted = "false";
      }
      if (!filter.domain_type && filter.domain_type !== "") {
        filter.domain_type = "l";
      }
    }

    if (state.page_name === "rules") {
      if (!filter.ordering) {
        filter.ordering = "-created_at";
      }
    }

    if (state.page_name === "dashboard") {
      let date = new Date();
      date.setDate(date.getDate() - 3);

      (!filter.visit_date_from || filter.visit_date_from === "") &&
        (filter.visit_date_from = moment(date).format("YYYY-MM-DD"));

      (!filter.visit_date_to || filter.visit_date_to === "") &&
        (filter.visit_date_to = moment().format("YYYY-MM-DD"));

      (!filter.is_new || filter.is_new === "") && (filter.is_new = true);
    }

    state.filter = { ...filter };
  },

  [types.CHANGE_FILTER](state, payload) {
    state.default_filter = false;
    let filter = {
      ...state.filter,
      offset: 0,
      ...payload,
    };

    if (!filter.visits) {
      delete filter.visits;
    }
    if (!filter.enabled && filter.enabled !== "false") {
      delete filter.enabled;
    }

    state.filter = { ...filter };
  },
};
