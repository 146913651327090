import * as TYPES from "./types";
import * as API from "./api";
import { requestStatus } from "../../../services";

export default {
  [TYPES.GET_STATS](context, filter) {
    API.getStats(filter).then(({ response, status }) => {
      if (status === requestStatus.success) {
        context.commit(TYPES.SET_STATS, response.data);
      }
    });
  },
};
