import * as TYPES from "./types";

export default {
  [TYPES.CHANGE_STATUS](state, payload) {
    state.status = {
      ...state.status,
      [payload.field]: payload.status,
      messages: payload.messages,
      change_field: payload.field,
    };
  },

  [TYPES.SET_DOMAINS](state, payload) {
    state.count = payload.count;
    state.list = [...payload.results];
  },

  [TYPES.SET_DOMAINS_FOR_SETTINGS](state, payload) {
    state.domains_list_for_settings = [...payload.results];
  },

  [TYPES.SET_NEW_DOMAINS](state, payload) {
    state.new_domains_count = payload.count;
    state.new_domains = [...payload.results];
  },

  [TYPES.SET_DOMAIN](state, payload) {
    state.details = { ...payload };
  },

  [TYPES.SET_DOMAIN_STATS](state, payload) {
    state.stats = payload;
  },

  [TYPES.SET_DOMAIN_URLS](state, payload) {
    state.urls = payload.results;
  },

  [TYPES.RECEIVE_TAGS](state, payload) {
    state.tags = [...payload.results];
  },
};
