import * as TYPES from "./types";

export default {
  [TYPES.ADD_BODY_CLASSNAME](context, className) {
    document.body.classList.add(className);
  },
  [TYPES.REMOVE_BODY_CLASSNAME](context, className) {
    document.body.classList.remove(className);
  },
  [TYPES.ADD_CLASSNAME](context, payload) {
    context.commit(TYPES.SET_CLASSNAME_BY_POSITION, payload);
  },
};
